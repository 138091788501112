$(document).ready(function () {
    var langFlag = Cookies.get('langFlag') || navigator.language;
    $('a[data-lang="' + langFlag + '"]').addClass('active');

    $("li.lang-group a").click(function (e) {
        var self = $(this)
        if (self.hasClass('active')) return;
        langFlag = self.data('lang')
        self.addClass('active')
        self.siblings().removeClass('active')

        if (langFlag === 'zh-CN') $(document.body).t2s();
        else if (langFlag === 'zh-TW') $(document.body).s2t();

        Cookies.set('langFlag', langFlag);
    })

    if (langFlag === 'zh-CN') $(document.body).t2s();

    $('.same-address').click(function () {
        $('input[name=mailing_address]').val($('input[name=address]').val())
    })
})

$(function () {

    // Country Banner
    function cb_reHeight() {
        if ($(window).width() >= 768) {
            $('.js_height').height($('.js_height').width() * 0.405);
        } else {
            $('.js_height').css('height', '');
        }
    }

    // News text
    function nt_reHeight() {
        var img = $('.js_img_hei'), txt = $('.js_txt_hei');
        img.css('height', '');
        txt.css('height', '');
        if ($(window).width() > 767) {
            if (img.height() > txt.height()) {
                txt.height(img.height());
            } else {
                img.height(txt.height());
            }
        }
    }

    function iframeResize() {
        // Find all iframes
        var $iframes = $("iframe");

		// Find &amp;amp;#x26; save the aspect ratio for all iframes
        $iframes.each(function () {
            $(this).data("ratio", this.height / this.width)
            // Remove the hardcoded width &amp;amp;#x26; height attributes
                .removeAttr("width")
                .removeAttr("height");
        });

		// Resize the iframes when the window is resized
        $(window).resize(function () {
            $iframes.each(function () {
                // Get the parent container&amp;amp;#x27;s width
                var width = $(this).parent().width();
                $(this).width(width)
                    .height(width * $(this).data("ratio"));
            });
		// Resize to fix all iframes on page load.
        }).resize();
    }

    cb_reHeight();
	iframeResize();

    $(window).resize(function () {
        cb_reHeight();
        nt_reHeight();
    });
    $(window).load(function () {
        nt_reHeight();
    });

});